<template>
    <div class="content">
        <van-nav-bar title="分享" left-arrow @click-left="onClickLeft" />
        <div class="box1">
            <div class="title">1.长按二维码分享给微信好友</div>
            <div class="qr" id="qr">
                <canvas id="canvas" v-show="false"></canvas>
            </div>
            <div class="btns">
                <div @click="mask = true">
                    <img src="~@/assets/images/distributionIndex/wx.png" alt="">
                    分享给微信好友
                </div>
                <div @click="savePicture()">
                    <img src="~@/assets/images/distributionIndex/savepic.png" alt="">
                    保存到相册
                </div>
            </div>
        </div>
        <div class="mask" v-if="mask" @click="mask = false">
            <img src="~@/assets/images/distributionIndex/shareText.png" alt="">
        </div>
    </div>
</template>

<script>
import isweixin from "@/untils/isweixin.js";
import Vue from "vue"
import { getWxConfig } from "@/untils/distubritionIndex.js";
import QRCode from "qrcode"
Vue.use(QRCode)

var wx = require('weixin-js-sdk') || window['wx'];
export default {
    data() {
        return {
            imgsrc: '',
            mask: false,
            jsSDK: {},
            titleInfo: {},
            sp: '',
            isIos: false

        }
    },
    mounted() {
        this.useqrcode()

        setTimeout(() => {

        }, 1000)

    },
    created() {


    },
    watch: {

    
    },

    methods: {

        onClickLeft() {
            this.$router.go(-1)
        },
        useqrcode() {
            let phone = JSON.parse(window.sessionStorage.getItem('UserInfo')).mobile
            let sp = JSON.parse(window.sessionStorage.getItem('UserInfo')).sp
            // console.log( );

            var canvas = document.getElementById("canvas");
            // 调用函数去生成二维码，参数依次为：二维码的容器、要生成的内容、回调函数  
            QRCode.toCanvas(canvas, `https://iot.yuna6.com/bigFlowPage?from=2&sp=${sp}&p_openid=&invite_phone=${phone}`, function (error) {
                if (error) {
                    console.error(error);
                } else {
                    // console.log("success!");
                }
            });
            this.convertCanvasToImage(canvas)
        },
        convertCanvasToImage(canvas) {
            var image = new Image();
            image.src = canvas.toDataURL("image/png");
            this.imgsrc = canvas.toDataURL("image/png");
            document.getElementById("qr").appendChild(image);

        },
        savePicture() {
            if (isweixin()) {
                return this.$toast('请长按二维码保存')
            }

            // this.saveFile(this.imgsrc, new Date().toLocaleString())
            const save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
            save_link.href = this.imgsrc;
            save_link.download = new Date().toLocaleString();
            const event = document.createEvent('MouseEvents');
            event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
            save_link.dispatchEvent(event);
        },

    }
}
</script>

<style lang="less" scoped>
.content {
    min-height: 100vh;
    background-color: #fff;

    .mask {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9999;
        background-color: rgba(0, 0, 0, 0.74);

        img {
            position: absolute;
            right: -30px;
            width: 369px;
            height: 255px;
        }
    }

    .box1 {
        width: 342px;
        // height: 342px;
        margin: 0 auto;
        border-radius: 22px;
        background: #F9F9F9;
        padding: 20px;
        box-sizing: border-box;
        margin-top: 20px;

        .title {
            text-align: center;
            font-size: 14px;
        }

        .qr {
            display: flex;
            justify-content: center;
            margin-top: 30px;
        }

        .btns {
            display: flex;
            justify-content: space-around;
            margin-top: 20px;

            img {
                width: 46px;
                height: 46px;
                margin-bottom: 10px;

            }

            div {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }

}
</style>